<template>
  <div class="section section__company">
    <div class="section__wrapper">
      <div class="title-with-icon title-with-icon_menu mb-5">
        <div class="title-with-icon__icon title-with-icon__icon_back"></div>
        <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
        <div class="title-with-icon__delimeter">/</div>
        <div class="title-with-icon__title">Противодействие коррупции</div>
      </div>

      <div class="content_18 mb-4">
        <div class="mb-1"><a href="http://минобрнауки.рф/%D0%BC%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%BE/%D0%BF%D1%80%D0%BE%D1%82%D0%B8%D0%B2%D0%BE%D0%B4%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D0%B5-%D0%BA%D0%BE%D1%80%D1%80%D1%83%D0%BF%D1%86%D0%B8%D0%B8/%D0%B0%D0%BA%D1%82%D1%8B" class="content_red content_underline">Нормативные правовые акты в сфере противодействия коррупции</a></div>
        <div class="mb-1"><a href="http://минобрнауки.рф/%D0%BC%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%BE/%D0%BF%D1%80%D0%BE%D1%82%D0%B8%D0%B2%D0%BE%D0%B4%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D0%B5-%D0%BA%D0%BE%D1%80%D1%80%D1%83%D0%BF%D1%86%D0%B8%D0%B8" class="content_red content_underline">Иные материалы по противодействию коррупции на сайте Минобрнауки РФ</a></div>
      </div>
      <div class="container-grid grid-3 grid-md-1 mb-md-10">
        <div class="container-grid__item">
          <div class="doc fill-white p-24 container-shadow">
            <div class="doc__content row">
              <div class="col-auto text-center">
                <div class="ext-wrapper m-0">
                  <img src="/pic/icon-doc.svg"/>

                </div>
              </div>
              <div class="col">
                <div class="doc__title">Положение о противодействии коррупции в Школе экспорта РЭЦ</div>
              </div>
            </div>
            <div class="doc__actions">
              <a href="https://exporteduru.servicecdn.ru/media/attachment/0001/21/951270ffc896c34f2568be48336919902e0b2d82.pdf" class="btn btn-outline-primary">Скачать</a>
            </div>
          </div>
        </div>
      </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoAntiCorruption",
  components: {CompanyNavigation},
  mounted() {
    this.$parent.init()
  }
}
</script>